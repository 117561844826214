/* eslint-disable prefer-destructuring */
/* eslint-disable no-alert */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { Container } from './styles';
import useSurveyData from '../../../hooks/useSurveyData';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import getSkeletonFromSurvey from '../../../utils/getSkeletonFromSurvey';
import getSurveyFromSkeleton from '../../../utils/getSurveyFromSkeleton';
import getEncodedSurvey from '../../../utils/getEncodedSurvey';
import updateSurveyTranslations from '../../../utils/updateSurveyTranslations';
import getQuestionsWithTranslations from '../../../utils/getQuestionsWithTranslations';
import getValidationQuestionByElementType from '../../../utils/getValidationQuestionByElementType';

import SurveyEditHeader from './surveyButtonsHeader';
import SkeletonTab from './surveySkeletonTab';
import SurveyCreatorTab from './surveyCreatorTab';
import getDecodedSurvey from '../../../utils/getDecodedSurvey';

interface Skeleton {
  surveyId: string;
  skeletonTree: any[];
}

interface Survey {
  idSurvey: number;
  name: string;
  title: string;
  showTitle: boolean;
  description: string;
  isTemplate: boolean;
  startTextId: number;
  finalTextId: number;
  projectId: number;
  productId: number;
  logo: {
    id: number;
    filePath: string;
  };
  pages: Array<{
    elements: any[];
  }>;
  triggers: any[];
  accesses: any[];
  subGroup: any;
  style: any;
  languages: number[];
  gdprId: number;
  isApproved?: boolean;
  isIntegrate: boolean;
  projectName: string;
  isLocked: boolean;
}

const SurveyEditPage: React.FC = () => {
  const { addToast } = useToast();
  const { surveyId } = useParams<{ surveyId: string }>();

  const {
    survey,
    setSurvey,
    accesses,
    surveyCreatorText,
    loading,
    pageTitle,
    reloadSurvey,
  } = useSurveyData(surveyId);

  const [selectedTab, setSelectedTab] = useState<'surveyCreator' | 'skeleton'>(
    'surveyCreator',
  );

  const [skeleton, setSkeleton] = useState<Skeleton | null>(null);

  const toggleSurveyBooleanField = useCallback(
    async (
      endpoint: string,
      currentValue: boolean | undefined,
      dataName: string,
    ) => {
      try {
        await reloadSurvey();
        await api.put(`/surveys/${survey?.idSurvey}/${endpoint}`, {
          [dataName]: !currentValue,
        });
        reloadSurvey();
      } catch (error) {
        console.log('error', error);
      }
    },
    [reloadSurvey, survey?.idSurvey],
  );

  const handleChangeIsLocked = useCallback(() => {
    toggleSurveyBooleanField('setLocked', survey?.isLocked, 'isLocked');
  }, [survey?.isLocked, toggleSurveyBooleanField]);

  const handleChangeIsReviewed = useCallback(() => {
    toggleSurveyBooleanField(
      'setIntegrate',
      survey?.isIntegrate,
      'isIntegrate',
    );
  }, [survey?.isIntegrate, toggleSurveyBooleanField]);

  const handleChangeIsDailyIntegration = useCallback(() => {
    toggleSurveyBooleanField(
      'setDailyIntegration',
      survey?.isIntegrate,
      'isIntegrate',
    );
  }, [survey?.isIntegrate, toggleSurveyBooleanField]);

  const handleSetSelectedTab = useCallback(
    async (event: ChangeEvent<any>, newValue: string) => {
      if (!survey) return;

      if (newValue === 'skeleton') {
        const latestSurveyResponse = await api.get(
          `/surveys/${survey?.idSurvey}`,
        );
        const decodedSurvey: any = getDecodedSurvey(latestSurveyResponse.data);
        const treeDataLocal = getSkeletonFromSurvey(
          JSON.stringify(decodedSurvey),
        );
        setSkeleton(treeDataLocal);
      } else {
        const dataChanged = JSON.parse(
          localStorage.getItem('@Victor:dataChanged') ?? 'false',
        );
        if (dataChanged) {
          if (
            !window.confirm(
              'Changes you made may not be saved. Are you sure you want to leave?',
            )
          ) {
            return;
          }
        }
        localStorage.setItem('@Victor:dataChanged', JSON.stringify(false));
        reloadSurvey();
      }

      setSelectedTab(newValue as 'surveyCreator' | 'skeleton');
    },
    [survey, reloadSurvey],
  );

  const callbackSaveSurvey = useCallback(
    async (text: string) => {
      if (typeof text !== 'string') {
        console.error(
          'callbackSaveSurvey chamado com tipo inválido:',
          typeof text,
          text,
        );
        addToast({
          type: 'error',
          title: 'Tipo de Dados Inválido',
          description: 'Dados inválidos fornecidos para salvar o survey.',
        });
        return;
      }

      if (!survey || survey.isLocked) {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description: `This survey is locked. Your modifications cannot be saved.`,
        });
        return;
      }

      try {
        let surveyToEncode: any;

        if (text && text?.trim() !== '') {
          surveyToEncode = JSON.parse(text);
        } else {
          surveyToEncode = JSON.parse(surveyCreatorText);
        }

        surveyToEncode.logo = {
          id: survey.logo?.id,
          filePath: survey.logo?.filePath,
        };
        surveyToEncode.startTextId = survey.startTextId;
        surveyToEncode.finalTextId = survey.finalTextId;
        surveyToEncode.gdprId = survey.gdprId;
        surveyToEncode.languages = survey.languages;
        surveyToEncode.style = survey.style;
        surveyToEncode.name = survey.name;

        const encodedSurveyToSave = getEncodedSurvey(
          JSON.stringify(surveyToEncode),
        );

        const responsePUT = await api.put(
          `/surveys/${survey?.idSurvey}`,
          encodedSurveyToSave,
        );

        if (responsePUT.data === null) {
          addToast({
            type: 'error',
            title: 'Survey Locked',
            description:
              'Changes not saved. This survey was locked on the server. Please reload the page.',
          });
        }

        if (survey.languages && survey.languages.length > 0) {
          await updateSurveyTranslations(encodedSurveyToSave, survey.idSurvey);
        }
      } catch (err) {
        console.log('err', err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while updating the survey. Please try again.',
        });
      }
    },
    [survey, addToast, surveyCreatorText],
  );

  const handleGenerateSurvey = useCallback(async () => {
    try {
      const latestSurveyResponse = await api.get(
        `/surveys/${survey?.idSurvey}`,
      );

      const decodedSurvey: any = getDecodedSurvey(latestSurveyResponse.data);

      const skeletonResponse = await api.get(
        `/skeletons/survey/${survey?.idSurvey}`,
      );

      if (!skeletonResponse.data) {
        addToast({
          type: 'error',
          title: 'Skeleton Not Found',
          description: 'No skeleton data found for this survey.',
        });
        return;
      }

      const skeletonTree = skeletonResponse.data.skeletonTree;
      const surveyElements = getSurveyFromSkeleton(skeletonTree);

      const mergedSurvey: any = { ...decodedSurvey };

      if (!mergedSurvey.pages || mergedSurvey.pages.length === 0) {
        mergedSurvey.pages = [{ elements: [] }];
      }

      mergedSurvey.pages[0].elements = surveyElements;

      const stringSurvey = JSON.stringify(mergedSurvey);

      await callbackSaveSurvey(stringSurvey);

      addToast({
        type: 'success',
        title: 'Success',
        description: 'Survey generated successfully!',
      });
    } catch (error) {
      console.log('Error generating survey from skeleton:', error);
      addToast({
        type: 'error',
        title: 'Generation Error',
        description:
          'An error occurred while generating the survey. Please try again.',
      });
    }
  }, [survey?.idSurvey, callbackSaveSurvey, addToast]);

  const updateAllQuestions = useCallback(async () => {
    if (!survey) return;
    const newElements: any[] = [];
    const questionsFromDb = await getQuestionsWithTranslations(survey);

    survey.pages[0].elements.forEach((element: any) => {
      if (getValidationQuestionByElementType(element.type)) {
        let questionIdToSearch = element?.questionId?.toString();
        let alternativeId: string | undefined;

        if (questionIdToSearch.includes('.')) {
          const questionIdSplitted = questionIdToSearch.split('.');
          questionIdToSearch = questionIdSplitted[0];
          alternativeId = questionIdSplitted[1];
        }

        const questionFromDb = questionsFromDb.find(
          (q: any) => q.questionId.toString() === questionIdToSearch,
        );

        if (questionFromDb) {
          if (alternativeId) {
            const alternativeFound = questionFromDb.alternatives.find(
              (alt: any) => alt.value === alternativeId,
            );
            const alternativeText = alternativeFound
              ? alternativeFound.text
              : questionFromDb.text;

            const updatedElement = {
              ...element,
              choices: questionFromDb.options,
              rateValues: questionFromDb.options,
              title: `${questionFromDb.questionId} - ${alternativeText}`,
            };
            newElements.push(updatedElement);
          } else {
            const updatedElement = {
              ...element,
              choices: questionFromDb.options,
              rateValues: questionFromDb.options,
              title: `${questionFromDb.questionId} - ${questionFromDb.text}`,
            };
            newElements.push(updatedElement);
          }
        } else {
          newElements.push(element);
        }
      } else if (element.type === 'matrix') {
        let newColumns: any[] = [];
        const matrixRows = element?.rows?.map((row: any) => {
          const qFound = questionsFromDb.find(
            (q: any) => q.questionId === row.questionId,
          );
          if (qFound) {
            newColumns = qFound.options;
            return { ...row, text: qFound.text };
          }
          return row;
        });

        const updatedMatrix = {
          ...element,
          rows: matrixRows,
          columns: newColumns,
        };
        newElements.push(updatedMatrix);
      } else {
        newElements.push(element);
      }
    });

    const surveyPageToSave = {
      ...survey.pages[0],
      elements: newElements,
    };
    const surveyToSave = { ...survey, pages: [surveyPageToSave] };

    await callbackSaveSurvey(JSON.stringify(surveyToSave));
    await reloadSurvey();
  }, [survey, callbackSaveSurvey, reloadSurvey]);

  const handleSilentSaveSurvey = useCallback(
    async (updatedSurvey: Survey) => {
      if (!updatedSurvey) return;
      try {
        setSurvey(updatedSurvey);

        const stringified = JSON.stringify(updatedSurvey);
        const encodedSurvey = getEncodedSurvey(stringified);
        await api.put(`/surveys/${survey?.idSurvey}`, encodedSurvey);
      } catch (error) {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Update error',
          description: 'Failed to update the survey. Please try again.',
        });
      }
    },
    [setSurvey, survey?.idSurvey, addToast],
  );

  return (
    <Container>
      {selectedTab === 'surveyCreator' && (
        <SurveyEditHeader
          pageTitle={pageTitle}
          surveyCreatorText={surveyCreatorText}
          survey={survey}
          isLoading={loading}
          onToggleLocked={handleChangeIsLocked}
          onToggleIntegrate={handleChangeIsReviewed}
          onToggleDailyIntegration={handleChangeIsDailyIntegration}
        />
      )}

      {!loading && (
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleSetSelectedTab}
        >
          <Tab value="surveyCreator" label="Survey Creator" />
          <Tab value="skeleton" label="Skeleton" />
        </Tabs>
      )}

      {selectedTab === 'skeleton' &&
        skeleton?.skeletonTree &&
        skeleton.skeletonTree.length > 0 && (
          <SkeletonTab
            skeleton={skeleton}
            surveyIdParam={survey?.idSurvey.toString() ?? ''}
            handleGenerateSurvey={handleGenerateSurvey}
            setSkeleton={setSkeleton}
            surveyIsLocked={survey?.isLocked ?? false}
            surveyLanguages={survey?.languages}
            loading={loading}
          />
        )}

      {selectedTab === 'surveyCreator' && (
        <SurveyCreatorTab
          surveyCreatorText={surveyCreatorText}
          callbackSaveSurvey={callbackSaveSurvey}
          updateAllQuestions={updateAllQuestions}
          handleSilentSaveSurvey={handleSilentSaveSurvey}
          accesses={accesses}
          idSurvey={survey?.idSurvey.toString() ?? ''}
          isLocked={survey?.isLocked ?? false}
          loading={loading}
        />
      )}
    </Container>
  );
};

export default SurveyEditPage;
