import React from 'react';
import SpinnerThreeDots from '../../../components/LoadingSpinner/SpinnerThreeDots';
import SortTree from '../../../components/Skeleton';

interface Skeleton {
  surveyId: string;
  skeletonTree: any[];
}

interface SkeletonTabProps {
  skeleton: Skeleton | null;
  surveyIdParam: string;
  handleGenerateSurvey: () => void;
  setSkeleton: React.Dispatch<React.SetStateAction<Skeleton | null>>;
  surveyIsLocked: boolean;
  surveyLanguages?: number[];
  loading: boolean;
}

const SkeletonTab: React.FC<SkeletonTabProps> = ({
  skeleton,
  surveyIdParam,
  handleGenerateSurvey,
  setSkeleton,
  surveyIsLocked,
  surveyLanguages,
  loading,
}) => {
  return (
    <div style={{ marginTop: 10 }}>
      {/* Título ou header, se quiser */}
      {/* <h2>Skeleton Tab</h2> */}

      {skeleton?.skeletonTree?.length ? (
        <SpinnerThreeDots loading={loading}>
          <SortTree
            surveyId={parseInt(surveyIdParam, 10)}
            setSkeleton={setSkeleton}
            treeDataParam={skeleton.skeletonTree}
            handleGenerateSurvey={handleGenerateSurvey}
            surveyIslocked={surveyIsLocked}
            surveyLanguages={surveyLanguages}
          />
        </SpinnerThreeDots>
      ) : (
        <p>No skeleton data to show</p>
      )}
    </div>
  );
};

export default SkeletonTab;
