import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

interface CustomLinkButtonProps {
  to: string;
  state?: object;
  marginRight?: string;
  width?: string;
  height?: string;
  target?: string;
  children: React.ReactNode;
  fontWeigth?: string;
  fontSize?: string;
}

const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({
  to,
  state,
  marginRight = '0px',
  width = 'auto',
  height = 'auto',
  target,
  children,
  fontWeigth,
  fontSize,
}) => (
  <Link to={{ pathname: to, state }} target={target}>
    <Button
      marginRight={marginRight}
      width={width}
      height={height}
      fontWeigth={fontWeigth}
      fontSize={fontSize}
    >
      {children}
    </Button>
  </Link>
);

export default CustomLinkButton;
