import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Survey from 'survey-react';
import ReactToPrint from 'react-to-print';
import { Container } from './styles';
import ButtonEb from '../../../components/Button';
import 'survey-react/modern.css';

import '../../../components/SurveyComponent/SurveyCustomWidgets/styles/fontawesome-stars.css';
// import 'emotion-ratings/dist/emotion-ratings.js';

import { EbSlider } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbSlider';
import { EbSliderPolarity } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbSliderPolarity';
import { EbVerticalPolarity } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbVerticalPolarity';
import { EbRating } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbRating';
import { EbStyledRating } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbStyledRating';
import { EbEmoticons } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbEmoticons';
import { EbEmoticons2 } from '../../../components/SurveyComponent/SurveyCustomWidgets/EbEmoticons2';

import BackButton from '../../../components/BackButton';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

Survey.StylesManager.applyTheme('modern');

Survey.Serializer.addProperty('question', 'questionId:number');
Survey.Serializer.addProperty('question', 'internalType:string');
Survey.Serializer.addProperty('question', 'matrixId:number');
Survey.Serializer.addProperty('question', {
  name: 'orderChoices:dropdown',
  category: 'general',
  choices: ['asc value', 'desc value'],
});

Survey.CustomWidgetCollection.Instance.add(EbRating(Survey), 'rating');
Survey.CustomWidgetCollection.Instance.add(EbSlider(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(
  EbSliderPolarity(Survey),
  'customtype',
);
Survey.CustomWidgetCollection.Instance.add(
  EbVerticalPolarity(Survey),
  'customtype',
);
Survey.CustomWidgetCollection.Instance.add(EbEmoticons(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(EbEmoticons2(Survey), 'customtype');
Survey.CustomWidgetCollection.Instance.add(
  EbStyledRating(Survey),
  'customtype',
);

interface State {
  idSurvey: number;
}

const ExportToPdf: React.FC = () => {
  const { state } = useLocation<State>();

  const { addToast } = useToast();
  const pdfRef = useRef<Survey.Survey>(null);
  const [model, setModel] = useState<Survey.SurveyModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!state?.idSurvey) {
      addToast({
        type: 'error',
        title: 'No Survey ID',
        description: 'No survey ID was provided. Cannot export to PDF.',
      });
      setLoading(false);
      return;
    }

    const fetchSurvey = async (): Promise<void> => {
      try {
        const response = await api.get(`/surveys/${state.idSurvey}`);
        const surveyFromDB = response.data;

        if (response.data.isLocked) {
          addToast({
            type: 'error',
            title: 'Survey Locked',
            description: 'You cannot export this survey because it is locked.',
          });
          setLoading(false);
          return;
        }

        const surveyModel = new Survey.Model(surveyFromDB || '{}');
        setModel(surveyModel);

        setLoading(false);
      } catch (error) {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error Loading Survey',
          description: 'Failed to load the survey from DB.',
        });
        setLoading(false);
      }
    };

    fetchSurvey();
  }, [state.idSurvey, addToast]);

  useEffect(() => {
    if (model) {
      const actionBars = document.getElementsByClassName(
        'sv-action-bar sv-action-bar--default-size-mode sv-footer sv-body__footer sv-clearfix',
      );
      if (actionBars.length > 0) {
        actionBars[0].remove();
      }

      const navComplete = document.getElementById('sv-nav-complete');
      if (navComplete) {
        navComplete.remove();
      }
    }
  }, [model]);

  if (loading) {
    return (
      <Container>
        <BackButton />
        <h1>Exporting Survey to PDF...</h1>
      </Container>
    );
  }

  if (!model) {
    return (
      <Container>
        <BackButton />
        <h1>Survey Not Available</h1>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton />

      <h1>Export Survey to PDF</h1>

      <Survey.Survey ref={pdfRef} model={model} showProgressBar="top" />

      <ReactToPrint
        content={() => pdfRef.current}
        trigger={() => (
          <ButtonEb marginTop={'20px'} marginBottom={'20px'}>
            Print to PDF!
          </ButtonEb>
        )}
      />
    </Container>
  );
};

export default ExportToPdf;
