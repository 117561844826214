/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { FiCamera } from 'react-icons/fi';
import api from '../../services/api';
import getEncodedSurvey from '../../utils/getEncodedSurvey';

import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';

import BackButton from '../../components/BackButton';
import ButtonEb from '../../components/Button';
import {
  Container,
  Content,
  ButtonUpload,
  UrlCopy,
  DeleteIcon,
} from './styles';

import { useToast } from '../../hooks/toast';
import updateSurveyTranslations from '../../utils/updateSurveyTranslations';
import getDecodedSurvey from '../../utils/getDecodedSurvey';

interface Logo {
  logoId: string;
  filename: string;
  name: string;
  url: string;
  imageType: string;
  isActive: boolean;
  server: string;
}

interface State {
  select: boolean;
  surveyId: number;
  logoId?: string;
  survey: any;
  pageTitle: string;
}

interface LogoObj {
  id: string;
  filePath: string;
}

const Logos: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const { state } = useLocation<State>();
  const [logos, setLogos] = useState<Logo[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const [latestSurveyFromDb, setLatestSurveyFromDb] = useState<any>(null);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'logoId', width: 120 },
    { columnName: 'originalname', width: 800 },
    { columnName: 'logo', width: 310, filteringEnabled: false },
    { columnName: 'copyUrl', width: 200, filteringEnabled: false },
    { columnName: 'delete', width: 80 },
  ]);

  const handleClickUrlCopy = useCallback(
    (row: any) => {
      navigator.clipboard.writeText(`${row.server}/${row.filename}`);
      addToast({
        type: 'success',
        title: 'URL copied',
        description: 'The logo was copied into clipboard.',
      });
    },
    [addToast],
  );

  const handleClickDelete = useCallback(
    async (row: any) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the logo ${row.logoId}?`,
        );

        if (userWantDelete) {
          const response = await api.delete(`/logos/${row.logoId}`);

          if (response.data?.deleted === true) {
            setLogos(prevLogos =>
              prevLogos.filter(logo => logo.logoId !== row.logoId),
            );
            addToast({
              type: 'success',
              title: 'Logo Deleted',
              description: 'Logo deleted successfully!',
            });
          } else {
            addToast({
              type: 'info',
              title: 'Logo is already in use',
              description:
                response.data?.message || 'This logo cannot be deleted.',
            });
          }
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error deleting logo',
          description: 'Error while deleting logo. Please, try again.',
        });
      }
    },
    [addToast],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'copyUrl',
      label: 'Copy',
      onClick: handleClickUrlCopy,
      icon: <UrlCopy />,
    },
    {
      columnName: 'delete',
      label: '',
      onClick: handleClickDelete,
      icon: <DeleteIcon />,
    },
  ]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await api.get('/logos');
        const logosData = response.data
          .map((logo: Logo) => ({
            ...logo,
            logoId: logo.logoId.toString(),
            server: response.request?.responseURL.replace('/logos', '/files'),
          }))
          .sort(
            (a: { logoId: any }, b: { logoId: any }) =>
              Number(b.logoId) - Number(a.logoId),
          );

        setLogos(logosData);

        if (state?.logoId) {
          setSelectedRowIds([state.logoId]);
        }
      } catch (error) {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error loading Logos',
          description: 'Failed to load logos from DB.',
        });
      }
    };

    const fetchSurvey = async () => {
      try {
        const response = await api.get(`/surveys/${state.surveyId}`);
        const surveyFromDatabase = response.data;
        const surveyDecoded = getDecodedSurvey(response.data);
        setLatestSurveyFromDb(surveyDecoded);

        // 3. Preenche o form com as informações do logo atual (se houver)
        if (surveyFromDatabase?.logo) {
          setSelectedRowIds([surveyFromDatabase.logo.id]);
        }
      } catch (error) {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error loading Survey',
          description: 'Failed to load the updated Survey from DB.',
        });
      }
    };

    fetchLogos();
    if (state?.surveyId) {
      fetchSurvey();
    }
  }, [state?.surveyId, state?.logoId, addToast]);

  const handleLogoChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const data = new FormData();
        data.append('logo', e.target.files[0]);

        try {
          const response = await api.post(`/logos`, data);
          const newLogo = response.data;

          setLogos(prevLogos => {
            const updatedLogos = [
              ...prevLogos,
              {
                ...newLogo,
                logoId: newLogo.logoId.toString(),
                server: response.request?.responseURL.replace(
                  '/logos',
                  '/files',
                ),
              },
            ];

            return updatedLogos.sort(
              (a, b) => Number(b.logoId) - Number(a.logoId),
            );
          });
          addToast({
            type: 'success',
            title: 'Upload successful',
            description: 'Logo was uploaded!',
          });
        } catch (error) {
          console.log(error);
          addToast({
            type: 'error',
            title: 'Error while uploading logo',
            description: 'Error while deleting logo. Please, try again.',
          });
        }
      }
    },
    [addToast],
  );

  const handleConfirm = useCallback(async () => {
    try {
      if (!latestSurveyFromDb) {
        addToast({
          type: 'error',
          title: 'Survey not loaded',
          description: 'The survey was not loaded. Please, try again',
        });
        return;
      }

      if (latestSurveyFromDb.isLocked) {
        addToast({
          type: 'error',
          title: 'Survey blocked',
          description:
            'You cannot change this survey because this survey is blocked.',
        });
        return;
      }

      const selectedLogo = logos.find(l => l.logoId === selectedRowIds[0]);

      if (!selectedLogo) {
        addToast({
          type: 'error',
          title: 'Logo Not Found',
          description: 'The selected logo was not found.',
        });
        return;
      }

      const logo: LogoObj = {
        id: selectedLogo.logoId,
        filePath: `${selectedLogo.server}/${selectedLogo.filename}`,
      };

      const mergedSurvey = {
        ...latestSurveyFromDb,
        logo,
      };

      const encodedSurveyToSave = getEncodedSurvey(
        JSON.stringify(mergedSurvey),
      );

      const response = await api.put(
        `/surveys/${state?.surveyId}`,
        encodedSurveyToSave,
      );

      await updateSurveyTranslations(
        encodedSurveyToSave,
        Number(mergedSurvey?.surveyId),
      );

      if (response?.data === null) {
        addToast({
          type: 'error',
          title: 'Survey Blocked',
          description: `This survey is blocked. Changes weren't saved.`,
        });
      } else {
        addToast({
          type: 'success',
          title: 'Success',
          description: 'The logo was selected sucessfully!',
        });
        history.push(`/surveys/${state?.surveyId}`);
      }
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Error while updating survey',
        description: `Error while updating survey. Please try again.`,
      });
    }
  }, [
    latestSurveyFromDb,
    logos,
    selectedRowIds,
    state?.surveyId,
    addToast,
    history,
  ]);

  return (
    <Container>
      {!!state?.select && <BackButton />}

      <h1>Logos</h1>

      {state?.pageTitle && <h2>{state?.pageTitle}</h2>}

      <ButtonUpload>
        <label htmlFor="logo">
          Upload New Logo <FiCamera />
          <input
            type="file"
            id="logo"
            onChange={handleLogoChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </label>
      </ButtonUpload>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={logos}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={!!state?.select}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'logoId'}
        />

        {!!state?.select && (
          <>
            <ButtonEb
              width="120px"
              height="40px"
              marginRight="30px"
              onClick={handleConfirm}
            >
              Confirm
            </ButtonEb>

            <Link to={''} onClick={history.goBack}>
              <ButtonEb width="120px" height="40px">
                Back
              </ButtonEb>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default Logos;
