import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';
import { useToast } from './toast';
import getDecodedSurvey from '../utils/getDecodedSurvey';
import EnumProductSubGroup from '../utils/enums/EnumProductSubGroup';

interface UseSurveyDataReturn {
  survey: Survey | null;
  setSurvey: React.Dispatch<React.SetStateAction<Survey | null>>; // <-- agora expomos o setSurvey também
  accesses: Access[];
  surveyCreatorText: string;
  loading: boolean;
  pageTitle: string;
  reloadSurvey: () => Promise<void>;
}

interface Page {
  elements: Element[];
}

interface Trigger {
  type: string;
  expression: string;
  setToName?: string;
  runExpression: string;
}

interface Access {
  segments: string[];
  name: string;
  accessId: number;
}

interface Logo {
  id: number;
  filePath: string;
}

interface Survey {
  idSurvey: number;
  name: string;
  title: string;
  showTitle: boolean;
  description: string;
  type?: string;
  isTemplate: boolean;
  startTextId: number;
  finalTextId: number;
  projectId: number;
  productId: number;
  logo: Logo;
  pages: Page[];
  triggers: Trigger[];
  accesses: Access[];
  subGroup: any;
  style: any;
  languages: number[];
  gdprId: number;
  isApproved?: boolean;
  isIntegrate: boolean;
  projectName: string;
  isLocked: boolean;
}

const useSurveyData = (idSurveyParam: string): UseSurveyDataReturn => {
  const { addToast } = useToast();

  const [survey, setSurvey] = useState<any | null>(null);
  const [accesses, setAccesses] = useState<Access[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageTitle, setPageTitle] = useState<string>('');

  const [surveyCreatorText, setSurveyCreatorText] = useState<string>(
    JSON.stringify({
      title: '',
      isTemplate: false,
      pages: [{ name: 'page1', elements: [] }],
    }),
  );

  const loadSurveyFromDatabase = useCallback(async () => {
    console.log(
      '[useSurveyData] loadSurveyFromDatabase start. setLoading(true)',
    );
    setLoading(true);

    try {
      console.log('[useSurveyData] calling GET /surveys/', idSurveyParam);
      const response = await api.get(`/surveys/${idSurveyParam}`, {
        params: { surveyId: idSurveyParam },
      });

      console.log('[useSurveyData] GET success. data:', response.data);

      const jsonFromDb = {
        gdprId: response.data?.gdprId,
        idSurvey: response.data.surveyId,
        pages: response.data.pages,
        triggers: response.data.triggers,
        description: response.data.description,
        name: response.data.name,
        title: response.data.title,
        showTitle: response.data?.showTitle,
        isTemplate: response.data.isTemplate,
        logo: response.data.logo,
        subGroup: response.data.subGroup,
        projectId: response.data.projectId,
        productId: response.data.productId,
        startTextId: response.data.startTextId,
        finalTextId: response.data.finalTextId,
        accesses: response.data.accessess,
        style: response.data.style,
        languages: response.data.languages,
        isLocked: response.data.isLocked,
        isIntegrate: response.data.isIntegrate,
        projectName: response.data.projectName,
        isApproved: response.data.isApproved ?? false,
      };

      // Monta o pageTitle
      let strSubGroup = '';
      switch (jsonFromDb.subGroup) {
        case 'Employees':
          strSubGroup = EnumProductSubGroup.Employees;
          break;
        case 'Management':
          strSubGroup = EnumProductSubGroup.Management;
          break;
        case 'Corporate':
          strSubGroup = EnumProductSubGroup.Corporate;
          break;
        case 'Retail':
          strSubGroup = EnumProductSubGroup.Retail;
          break;
        case 'Wealthy':
          strSubGroup = EnumProductSubGroup.Wealthy;
          break;
        default:
          break;
      }

      if (jsonFromDb.isTemplate) {
        setPageTitle(`${jsonFromDb.name}`);
      } else {
        const projectNameFromDatabase = response.data.projectName;
        setPageTitle(
          `${projectNameFromDatabase} - ${jsonFromDb.name} - ${strSubGroup}`,
        );
      }

      // Decodifica e atualiza estado
      const decodedSurvey = getDecodedSurvey(jsonFromDb);
      setSurvey(decodedSurvey);
      setSurveyCreatorText(JSON.stringify(decodedSurvey));

      // Carrega accesses
      if (jsonFromDb.idSurvey) {
        const respAccesses = await api.get(
          `/accesses/survey/${jsonFromDb.idSurvey}`,
          {
            params: { surveyId: jsonFromDb.idSurvey },
          },
        );
        setAccesses(respAccesses.data);
      }
    } catch (error) {
      console.log('[useSurveyData] GET error:', error);
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Failed to load survey data.',
      });
    } finally {
      console.log('[useSurveyData] FINALLY. setLoading(false)');
      setLoading(false);
    }
  }, [addToast, idSurveyParam]);

  // Carrega ao montar
  useEffect(() => {
    loadSurveyFromDatabase();
  }, [loadSurveyFromDatabase]);

  return {
    survey,
    setSurvey, // <---- exposto aqui
    accesses,
    surveyCreatorText,
    loading,
    pageTitle,
    reloadSurvey: loadSurveyFromDatabase,
  };
};

export default useSurveyData;
