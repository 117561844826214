import React from 'react';
import {
  Container,
  LoadingCircle,
  LoadingContainerVariants,
  LoadingCircleVariants,
  LoadingCircleTransition,
} from './styles';

type SpinnerThreeDotsProps = {
  loading: boolean;
  children: React.ReactNode;
};

/**
 * Exibe o spinner caso loading=true; caso contrário, renderiza 'children'.
 */
const SpinnerThreeDots: React.FC<SpinnerThreeDotsProps> = ({
  loading,
  children,
}) => {
  if (loading) {
    return (
      <Container
        variants={LoadingContainerVariants}
        initial="start"
        animate="end"
      >
        <p>Loading</p>
        <LoadingCircle
          variants={LoadingCircleVariants}
          transition={LoadingCircleTransition}
        />
        <LoadingCircle
          variants={LoadingCircleVariants}
          transition={LoadingCircleTransition}
        />
        <LoadingCircle
          variants={LoadingCircleVariants}
          transition={LoadingCircleTransition}
        />
      </Container>
    );
  }

  // Quando loading é false, retorna o conteúdo filho normalmente
  return <>{children}</>;
};

export default SpinnerThreeDots;
