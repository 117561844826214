import React from 'react';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { Lock, LockOpen } from '@material-ui/icons';

import Button from '../../../components/Button';
import CustomLinkButton from '../../../components/SurveyCreatorComponents/CustomLinkButton';
import EnumProductSubGroup from '../../../utils/enums/EnumProductSubGroup';

import { TitleLockedIcon, ButtonLock, CheckIntegrate } from './styles';

interface Logo {
  id: number;
  filePath: string;
}

interface Survey {
  idSurvey: number;
  name: string;
  isLocked: boolean;
  isTemplate: boolean;
  startTextId: number;
  finalTextId: number;
  logo: Logo;
  subGroup: string;
  languages: number[];
  projectName: string;
  isApproved?: boolean;
  isIntegrate: boolean;
  projectId: number;
}

interface SurveyEditHeaderProps {
  pageTitle: string;
  surveyCreatorText: string;
  survey: Survey | null;
  isLoading: boolean;
  onToggleLocked: () => void;
  onToggleIntegrate: () => void;
  onToggleDailyIntegration: () => void;
}

const SurveyEditHeader: React.FC<SurveyEditHeaderProps> = ({
  pageTitle,
  surveyCreatorText,
  survey,
  isLoading,
  onToggleLocked,
  onToggleIntegrate,
}) => {
  if (isLoading || !survey) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 25,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TitleLockedIcon>
          <h1>{pageTitle}</h1>
        </TitleLockedIcon>

        <ButtonLock>
          {survey.isLocked ? <Lock /> : <LockOpen />}
          <Switch
            checked={survey.isLocked ?? false}
            onChange={onToggleLocked}
            color="primary"
          />
        </ButtonLock>
      </div>

      <div style={{ marginTop: 8 }}>
        <CustomLinkButton
          to="/questions/"
          state={{
            select: true,
            surveyId: survey.idSurvey,
            pageTitle,
          }}
          marginRight="30px"
          width="150px"
          height="40px"
          fontWeigth="500"
          fontSize="13px"
        >
          Add Questions
        </CustomLinkButton>

        <Link
          to={{
            pathname: `/surveys/${survey.idSurvey}/accesses`,
            state: {
              surveyName: survey.name,
              subgroup:
                Object.values(EnumProductSubGroup)[
                  Object.keys(EnumProductSubGroup).indexOf(
                    survey?.subGroup || '',
                  )
                ],
              pageTitle,
              isTemplate: survey.isTemplate,
              languages: survey.languages,
            },
          }}
        >
          <Button marginRight="35px" width="130px" height="40px">
            Links
          </Button>
        </Link>
        {!survey?.isTemplate && (
          <>
            <Link
              to={{
                pathname: `/surveyTexts/type/start`,
                state: {
                  select: true,
                  surveyId: survey.idSurvey,
                  survey: survey && survey,
                  selectedStartTextId: survey?.startTextId,
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="130px" height="40px">
                Start Text
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/surveyTexts/type/final`,
                state: {
                  select: true,
                  surveyId: survey?.idSurvey,
                  survey: survey && survey,
                  selectedFinalTextId: survey?.finalTextId,
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="130px" height="40px">
                Final Text
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/gdpr/`,
                state: {
                  select: true,
                  surveyId: survey?.idSurvey,
                  survey: survey && survey,
                },
              }}
            >
              <Button marginRight="35px" width="130px" height="40px">
                GDPR
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/logos`,
                state: {
                  select: true,
                  surveyId: survey.idSurvey,
                  survey: survey && survey,
                  logoId: survey?.logo?.id?.toString(),
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="100px" height="40px">
                Logo
              </Button>
            </Link>

            <Link
              to={{
                pathname: '/style',
                state: {
                  surveyId: survey.idSurvey,
                  survey: survey && survey,
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="150px" height="40px">
                Style
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/surveys/${survey?.idSurvey}/surveyTranslations`,
                state: {
                  surveyId: survey.idSurvey,
                  survey: survey && survey,
                  isLocked: survey?.isLocked ?? false,
                },
              }}
            >
              <Button marginRight="35px" width="150px" height="40px">
                Translations
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/surveys/${survey?.idSurvey}/export`,
                state: {
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="150px" height="40px">
                Export Results
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/exportDataWarehouse`,
                state: {
                  idSurvey: survey?.idSurvey,
                },
              }}
            >
              <Button marginRight="35px" width="210px" height="40px">
                Export DataWarehouse
              </Button>
            </Link>

            <Link
              to={{
                pathname: `/ExportToPdf`,
                state: {
                  surveyCreatorText,
                  idSurvey: survey?.idSurvey,
                },
              }}
            >
              <Button marginRight="35px" width="130px" height="40px">
                Export PDF
              </Button>
            </Link>
            <Link
              to={{
                pathname: `/surveys/${survey?.idSurvey}/answers`,
                state: {
                  isReviewed: survey?.isApproved ?? false,
                  userGroup: survey?.subGroup,
                  idSurvey: survey?.idSurvey,
                  projectName: survey?.projectName,
                  pageTitle,
                },
              }}
            >
              <Button marginRight="35px" width="150px" height="40px">
                Open Answers
              </Button>
            </Link>
          </>
        )}

        <Link
          to={{
            pathname: `/surveys/${survey?.idSurvey}/runAnalyser`,
            state: {
              surveyName: survey?.name,
              idSurvey: survey?.idSurvey,
              projectId: survey?.projectId,
              userGroup: survey?.subGroup,
              projectName: survey?.projectName,
            },
          }}
        >
          <Button marginRight="35px" width="150px" height="40px">
            Run Analyser
          </Button>
        </Link>

        {survey?.isTemplate && (
          <Link
            to={`/products/surveyTemplate/${survey.idSurvey}`}
            target="_blank"
          >
            <Button
              type="button"
              marginRight="35px"
              width="200px"
              height="40px"
            >
              View Demo
            </Button>
          </Link>
        )}

        <CheckIntegrate>
          <Switch
            checked={survey.isIntegrate ?? false}
            onChange={onToggleIntegrate}
            color="primary"
          />
          {'Integrate with data warehouse'}
        </CheckIntegrate>

        {/* <CheckIntegrate>
          <Switch
            checked={survey.isIntegrate ?? false}
            onChange={onToggleDailyIntegration}
            color="primary"
          />
          {'Daily Integration with data warehouse'}
        </CheckIntegrate> */}
      </div>
    </div>
  );
};

export default SurveyEditHeader;
