import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns } from './columns';

import ButtonEb from '../../components/Button';

import { Container, Content, Button, EditIcon } from './styles';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getEncodedSurvey from '../../utils/getEncodedSurvey';
import updateSurveyTranslations from '../../utils/updateSurveyTranslations';
import getDecodedSurvey from '../../utils/getDecodedSurvey';

interface GDPR {
  surveyTextId: string;
  title: string;
  text: string;
  translations: Translation[];
  textFootnote: string;
  textLinkFootnote: string;
}

interface Translation {
  text: string;
  languageId: number;
  textFootnoteTranslated: string;
  textLinkFootnoteTranslated: string;
}

interface State {
  select: boolean;
  surveyId: number;
  survey: any;
}

const GDPR: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [gdprs, setGDPRSs] = useState<GDPR[]>([]);
  const { state } = useLocation<State>();
  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const [latestSurveyFromDb, setLatestSurveyFromDb] = useState<any>(null);

  const { addToast } = useToast();

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'gdprId', width: 120 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/gdpr/edit/${row.gdprId}`);
    },
    [history],
  );

  const [actionColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditIcon />,
    },
  ]);

  useEffect(() => {
    setLoading(true);

    api
      .get('gdpr')
      .then(response => {
        const data = response.data.map((gdpr: GDPR) => {
          return {
            ...gdpr,
          };
        });
        setGDPRSs(data);

        if (state?.survey?.gdprId) {
          setSelectedRowIds([state.survey.gdprId]);
        }
      })
      .catch(err => {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Error loading GDPR list',
          description: 'Failed to load GDPR list from DB.',
        });
      })
      .finally(() => {
        setLoading(false);
      });

    if (state?.surveyId) {
      api
        .get(`/surveys/${state.surveyId}`)
        .then(resp => {
          const surveyDecoded = getDecodedSurvey(resp.data);
          setLatestSurveyFromDb(surveyDecoded);
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Error loading Survey',
            description: 'Failed to load the updated Survey from DB.',
          });
        });
    }
  }, [state, addToast, history]);

  const handleConfirm = useCallback(async () => {
    try {
      if (!latestSurveyFromDb) {
        throw new Error('No up-to-date survey loaded. Cannot proceed.');
      }

      const mergedSurvey = {
        ...latestSurveyFromDb,
        gdprId: selectedRowIds[0],
      };

      const encodedSurveyToSave = getEncodedSurvey(
        JSON.stringify(mergedSurvey),
      );

      const response = await api.put(
        `/surveys/${state?.surveyId}`,
        encodedSurveyToSave,
      );

      if (response.data === null) {
        addToast({
          type: 'error',
          title: 'Survey Locked',
          description: 'This survey is locked. No changes were saved.',
        });
        return;
      }

      await updateSurveyTranslations(
        encodedSurveyToSave,
        Number(mergedSurvey?.surveyId),
      );

      addToast({
        type: 'success',
        title: 'Success',
        description: 'The survey text was selected successfully!',
      });

      history.push(`/surveys/${state?.surveyId}`);
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Updating Error',
        description:
          'An error occurred while updating the survey. Please try again.',
      });
    }
  }, [latestSurveyFromDb, selectedRowIds, state?.surveyId, addToast, history]);

  return (
    <Container className={loading ? 'loading' : ''}>
      <h1>General Data Protection Regulation (GDPR)</h1>

      <Link to={{ pathname: `/gdpr/new` }}>
        <Button variant="contained" type="button">
          New GDPR
        </Button>
      </Link>

      <Content>
        <Table
          columnsProp={columns}
          dataProp={gdprs}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={!!state?.select}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={actionColumns}
          hasFilterRow={true}
          idName={'gdprId'}
        />

        {!!state?.select && (
          <>
            <ButtonEb
              width="120px"
              height="40px"
              marginRight="30px"
              onClick={handleConfirm}
            >
              Confirm
            </ButtonEb>

            <Link to={''} onClick={history.goBack}>
              <ButtonEb width="120px" height="40px">
                Back
              </ButtonEb>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default GDPR;
