import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import getDecodedSurvey from '../../../utils/getDecodedSurvey'; // se o /surveys retorna encodado

import FormStyle from './Form';
import { Container } from './styles';

interface Style {
  urlFavicon: string;
  browserTitle: string;
  buttonsColor: string;
  overlayBoxColor: string;
  urlLogo: string;
  urlRadarChart: string;
  headerImagePath?: string;
  headerImageId?: number;
  hideProgressText: boolean;
  showConfettiRain: boolean;
  backgroundImagePath?: string;
  backgroundImageId?: number;
}

interface State {
  surveyId: number;
  survey: any;
  pageTitle: string;
}

const StylePage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { state } = useLocation<State>();

  const [latestSurveyFromDb, setLatestSurveyFromDb] = useState<any>(null);

  useEffect(() => {
    if (!state?.surveyId) {
      addToast({
        type: 'error',
        title: 'No surveyId',
        description: 'Cannot load style because no surveyId was provided.',
      });
      return;
    }

    api
      .get(`/surveys/${state.surveyId}`)
      .then(response => {
        const surveyFromDatabase = response.data;
        const surveyDecoded = getDecodedSurvey(response.data);
        setLatestSurveyFromDb(surveyDecoded);

        if (surveyFromDatabase?.style) {
          formRef.current?.setFieldValue(
            'showConfettiRain',
            surveyFromDatabase.style.showConfettiRain ?? false,
          );
          formRef.current?.setFieldValue(
            'hideProgressText',
            surveyFromDatabase.style.hideProgressText ?? false,
          );
          formRef.current?.setFieldValue(
            'browserTitle',
            surveyFromDatabase.style.browserTitle ?? '',
          );
          formRef.current?.setFieldValue(
            'buttonsColor',
            surveyFromDatabase.style.buttonsColor ?? '',
          );
          formRef.current?.setFieldValue(
            'overlayBoxColor',
            surveyFromDatabase.style.overlayBoxColor ?? '',
          );
          formRef.current?.setFieldValue(
            'urlFavicon',
            surveyFromDatabase.style.urlFavicon ?? '',
          );
          formRef.current?.setFieldValue(
            'urlLogo',
            surveyFromDatabase.style.urlLogo ?? '',
          );
          formRef.current?.setFieldValue(
            'urlRadarChart',
            surveyFromDatabase.style.urlRadarChart ?? '',
          );

          // header image
          formRef.current?.setFieldValue(
            'headerImagePath',
            surveyFromDatabase.style.header?.filePath ?? '',
          );
          const headerFilePath =
            surveyFromDatabase.style.header?.filePath ?? '';
          const headerFieldLabel = headerFilePath
            ? headerFilePath.substring(headerFilePath.lastIndexOf('/') + 1)
            : '';
          formRef.current?.setFieldValue('headerImageId', {
            value: surveyFromDatabase.style.header?.id,
            filePath: surveyFromDatabase.style.header?.filePath,
            label: headerFieldLabel,
          });

          // background image
          formRef.current?.setFieldValue(
            'backgroundImagePath',
            surveyFromDatabase.style.background?.filePath ?? '',
          );
          const backgroundFilePath =
            surveyFromDatabase.style.background?.filePath ?? '';
          const backgroundFieldLabel = backgroundFilePath
            ? backgroundFilePath.substring(
                backgroundFilePath.lastIndexOf('/') + 1,
              )
            : '';
          formRef.current?.setFieldValue('backgroundImageId', {
            value: surveyFromDatabase.style.background?.id,
            filePath: surveyFromDatabase.style.background?.filePath,
            label: backgroundFieldLabel,
          });
        }
      })
      .catch(error => {
        console.log(error);
        addToast({
          type: 'error',
          title: 'Error loading Survey style',
          description: 'Failed to load style from DB.',
        });
      });
  }, [state?.surveyId, addToast]);

  const handleSubmit: SubmitHandler<Style> = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        // 2) Podemos checar se latestSurveyFromDb está locked
        if (latestSurveyFromDb?.isLocked) {
          addToast({
            type: 'error',
            title: 'Survey Locked',
            description:
              'You cannot change the style because this survey is locked.',
          });
          return;
        }

        // 3) Chama rota PUT /surveys/:surveyId/style com “data”
        // pois você disse que não quer mudar a rota
        await api.put(`/surveys/${state.surveyId}/style`, data);

        // Se você precisar atualizar translations de algo, poderia chamar:
        //   await updateSurveyTranslations(...);
        // mas se "style" não afeta translations, pode omitir.

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The style was saved successfully!',
        });

        history.push(`/surveys/${state.surveyId}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        console.log(err);
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while saving style. Please try again.',
        });
      }
    },
    [addToast, history, latestSurveyFromDb, state?.surveyId],
  );

  return (
    <Container>
      <h1>Style</h1>
      {state?.pageTitle && <h2>{state?.pageTitle}</h2>}

      <FormStyle
        formRef={formRef}
        handleSubmit={handleSubmit}
        initialData={latestSurveyFromDb}
        buttonText="Save"
      />
    </Container>
  );
};

export default StylePage;
