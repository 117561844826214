import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';

import Table from '../../components/DevExpressTable/DevExpressTable';
import { IActionColumn } from '../../components/DevExpressTable/ActionsColumn';
import { columns, columnsSelect } from './columns';

import BackButton from '../../components/BackButton';
import ButtonEb from '../../components/Button';

import { Container, Content, Button, EditIcon, DeleteIcon } from './styles';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getEncodedSurvey from '../../utils/getEncodedSurvey';
import updateSurveyTranslations from '../../utils/updateSurveyTranslations';
import getDecodedSurvey from '../../utils/getDecodedSurvey';

interface SurveyText {
  surveyTextId: string;
  title: string;
  text: string;
  isStartText: boolean;
}

interface ParamTypes {
  textType: string;
}

interface State {
  select: boolean;
  surveyId: number;
  survey: any;
  selectedStartTextId?: string;
  selectedFinalTextId?: string;
  pageTitle?: string;
}

const SurveyTexts: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [surveyTexts, setSurveyTexts] = useState<SurveyText[]>([]);

  const { textType } = useParams<ParamTypes>();

  const [pageTitle, setPageTitle] = useState('Start');

  const { state } = useLocation<State>();

  const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
  const { addToast } = useToast();

  const [latestSurveyFromDb, setLatestSurveyFromDb] = useState<any>(null);

  const [tableColumnExtensions] = useState([
    { columnFilteringEnabled: false },
    { columnName: 'surveyTextId', width: 120 },
    { columnName: 'title', width: 300 },
    { columnName: 'text', width: 1280 },
    { columnName: 'delete', width: 80 },
  ]);

  const handleClickEdit = useCallback(
    (row: any) => {
      history.push(`/surveyTexts/edit/${row.surveyTextId}`);
    },
    [history],
  );

  const handleClickDelete = useCallback(
    async (row: any) => {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const userWantDelete = confirm(
          `Are you sure you want to delete the ${textType} text ${row.surveyTextId}?`,
        );

        if (userWantDelete) {
          const response = await api.delete(
            `/surveyTexts/${row.surveyTextId}/${textType}`,
          );

          if (response.data?.deleted === true) {
            history.push('/projects');
            history.push(`/surveyTexts/type/${textType}`);
          } else {
            addToast({
              type: 'info',
              title: `${textType} text is being used`,
              description: response.data?.message,
            });
          }
        }
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Updating Error',
          description: `An error occurred while deleting this ${textType} text, please try again.`,
        });
      }
    },
    [addToast, history, textType],
  );

  const [actionColumns, setActionsColumns] = useState<IActionColumn[]>([
    {
      columnName: 'edit',
      label: '',
      onClick: handleClickEdit,
      icon: <EditIcon />,
    },
    {
      columnName: 'delete',
      label: '',
      onClick: handleClickDelete,
      icon: <DeleteIcon />,
    },
  ]);

  useEffect(() => {
    setLoading(true);

    setPageTitle(textType === 'start' ? 'Start' : 'Final');

    setActionsColumns([
      {
        columnName: 'edit',
        label: '',
        onClick: handleClickEdit,
        icon: <EditIcon />,
      },
      {
        columnName: 'delete',
        label: '',
        onClick: handleClickDelete,
        icon: <DeleteIcon />,
      },
    ]);

    const url =
      textType === 'start'
        ? 'surveyTexts/textType/true'
        : 'surveyTexts/textType/false';

    api.get(url).then(response => {
      const data = response.data.map((surveyText: SurveyText) => {
        return {
          ...surveyText,
          surveyTextId: surveyText.surveyTextId.toString(),
        };
      });
      setSurveyTexts(data);

      if (state?.selectedStartTextId && textType === 'start') {
        setSelectedRowIds([state.selectedStartTextId]);
      } else if (state?.selectedFinalTextId && textType !== 'start') {
        setSelectedRowIds([state.selectedFinalTextId]);
      }
    });

    if (state?.surveyId) {
      api
        .get(`/surveys/${state.surveyId}`)
        .then(resp => {
          const surveyFromDb = getDecodedSurvey(resp.data);
          setLatestSurveyFromDb(surveyFromDb);
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Error loading Survey',
            description: 'Failed to load the updated Survey from DB.',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [
    textType,
    state?.selectedStartTextId,
    state?.selectedFinalTextId,
    state?.surveyId,
    handleClickEdit,
    handleClickDelete,
    addToast,
  ]);

  const handleConfirm = useCallback(async () => {
    try {
      const surveyJson = latestSurveyFromDb;

      if (!surveyJson) {
        throw new Error('No survey loaded to update.');
      }

      const mergedSurvey = {
        ...surveyJson,
        ...(textType === 'start'
          ? { startTextId: Number(selectedRowIds[0]) }
          : { finalTextId: Number(selectedRowIds[0]) }),
      };

      const encodedSurveyToSave = getEncodedSurvey(
        JSON.stringify(mergedSurvey),
      );

      const response = await api.put(
        `/surveys/${state?.surveyId}`,
        encodedSurveyToSave,
      );

      await updateSurveyTranslations(
        encodedSurveyToSave,
        Number(mergedSurvey?.surveyId),
      );

      if (response.data === null) {
        addToast({
          type: 'error',
          title: 'Updating Error',
          description: `This survey is locked. No changes were saved.`,
        });
      } else {
        history.push(`/surveys/${state?.surveyId}`);

        addToast({
          type: 'success',
          title: 'Success',
          description: 'The survey text was selected successfully!',
        });
      }
    } catch (err) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Updating Error',
        description: `An error occurred while updating the survey. Please try again.`,
      });
    }
  }, [
    latestSurveyFromDb,
    state?.surveyId,
    textType,
    selectedRowIds,
    history,
    addToast,
  ]);

  return (
    <Container className={loading ? 'loading' : ''}>
      {!!state?.select && <BackButton />}

      <h1>{pageTitle} Texts</h1>

      {state?.pageTitle && <h2>{state?.pageTitle}</h2>}

      {!state?.select && (
        <Link
          to={{
            pathname: `/surveyTexts/new`,
            state: {
              isStartText: textType === 'start',
            },
          }}
        >
          <Button variant="contained" type="button">
            New {pageTitle} Text
          </Button>
        </Link>
      )}

      <Content>
        <Table
          columnsProp={!state?.select ? columns : columnsSelect}
          dataProp={surveyTexts}
          selectionProp={selectedRowIds?.map(String)}
          multiSelection={false}
          setSelectedRowId={setSelectedRowIds}
          checkboxSelection={!!state?.select}
          tableColumnExtensions={tableColumnExtensions}
          actionColumns={state?.select ? [] : actionColumns}
          hasFilterRow={true}
          defaultPageSize={10}
          idName={'surveyTextId'}
        />

        {!!state?.select && (
          <>
            <ButtonEb
              width="120px"
              height="40px"
              marginRight="30px"
              onClick={handleConfirm}
            >
              Confirm
            </ButtonEb>

            <Link to={''} onClick={history.goBack}>
              <ButtonEb width="120px" height="40px">
                Back
              </ButtonEb>
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default SurveyTexts;
