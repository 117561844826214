import React from 'react';
import SpinnerThreeDots from '../../../components/LoadingSpinner/SpinnerThreeDots';
import SurveyCreatorComponent from '../../../components/SurveyComponent/SurveyCreatorComponent/SurveyCreatorComponent';

interface SurveyCreatorTabProps {
  surveyCreatorText: string;
  callbackSaveSurvey: (text: string) => Promise<void>;
  updateAllQuestions: () => Promise<void>;
  handleSilentSaveSurvey: (updatedSurvey: any) => Promise<void>;
  accesses: any[];
  idSurvey?: string;
  isLocked: boolean;
  loading: boolean;
}

const SurveyCreatorTab: React.FC<SurveyCreatorTabProps> = ({
  surveyCreatorText,
  callbackSaveSurvey,
  updateAllQuestions,
  handleSilentSaveSurvey,
  accesses,
  idSurvey,
  isLocked,
  loading,
}) => {
  return (
    <div style={{ marginTop: 10 }}>
      <SpinnerThreeDots loading={loading}>
        <SurveyCreatorComponent
          surveyText={surveyCreatorText}
          callbackSaveSurvey={callbackSaveSurvey}
          updateAllQuestions={updateAllQuestions}
          handleSilentUpdate={handleSilentSaveSurvey}
          accesses={accesses}
          idSurvey={idSurvey}
          isLocked={isLocked}
        />
      </SpinnerThreeDots>
    </div>
  );
};

export default SurveyCreatorTab;
